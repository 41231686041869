
@font-face {
  font-family: Pretendard;
  src: 
  url('./Pretendard-Thin.woff') format('font-woff'),
  url('./Pretendard-Thin.subset.woff') format('font-woff'),
  url('./Pretendard-Thin.subset.woff2') format('woff2');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: Pretendard;
  src: 
  url('./Pretendard-Light.woff') format('font-woff'),
  url('./Pretendard-Light.subset.woff') format('font-woff'),
  url('./Pretendard-Light.subset.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: Pretendard;
  src: 
  url('./Pretendard-Medium.woff') format('font-woff'),
  url('./Pretendard-Medium.subset.woff') format('font-woff'),
  url('./Pretendard-Medium.subset.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: Pretendard;
  src: 
  url('./Pretendard-SemiBold.woff') format('font-woff'),
  url('./Pretendard-SemiBold.subset.woff') format('font-woff'),
  url('./Pretendard-SemiBold.subset.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: Pretendard;
  src: 
  url('./Pretendard-Bold.woff') format('font-woff'),
  url('./Pretendard-Bold.subset.woff') format('font-woff'),
  url('./Pretendard-Bold.subset.woff2') format('woff2');
  font-style: normal;
  font-weight: 800;
}