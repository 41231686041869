@font-face {
  font-family: NanumSquare;
  src: url("./NanumSquare_acB.ttf") format("acB"),
    url("./NanumSquare_acEB.ttf") format("acEB"),
    url("./NanumSquareB.ttf") format("B");
  font-style: bold;
  font-weight: 300;
}

@font-face {
  font-family: NanumSquare;
  src: url("./NanumSquare_acL.ttf") format("acL"),
    url("./NanumSquareL.ttf") format("L");
  font-style: Light;
  font-weight: 300;
}

@font-face {
  font-family: NanumSquare;
  src: url("./NanumSquare_acR.ttf") format("acR"),
    url("./NanumSquareEB.ttf") format("EB"),
    url("./NanumSquareR.ttf") format("R");
  font-style: normal;
  font-weight: 300;
}
